import React from 'react'

const Contact = () => (
  <div className='contact'>
    <div className='contact-container'>
      <h2 id='contact' className='section-title white'>Contact</h2>
      <p className='email'>contact@theanthonypereira.com</p>
    </div>
  </div>
)

export default Contact
